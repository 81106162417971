
import { ActionLog, Landscape, Version } from '@icepanel/platform-api-client'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
import { getModule } from 'vuex-module-decorators'

import * as sort from '@/helpers/sort'
import { AlertModule } from '@/modules/alert/store'
import { CommentModule } from '@/modules/comment/store'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { VersionModule } from '@/modules/version/store'

import resolveActionLabel, { IActionLabel, IActionLabelContentLink } from '../helpers/action-label'
import * as analytics from '../helpers/analytics'
import { HistoryModule } from '../store'

interface IObjectItem extends ActionLog {
  actionLabel: IActionLabel
  content: { text: string, icon?: string, tooltip?: string, link?: RawLocation }[]
  // changes?: boolean
  // expanded?: boolean
  loading?: boolean
  performedByLabel: { text?: string, icon?: string }
}

@Component({
  name: 'History'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  commentModule = getModule(CommentModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  historyModule = getModule(HistoryModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly virtualScrollRef?: { $el: HTMLElement, onScroll: () => void }

  windowWidth = 0
  scrollY = 0
  scrollYVisible = false
  limit = 20
  startActionLogId: string | undefined

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.currentVersion?.landscapeId || this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  // get expandedActionLogIds () {
  //   return this.$queryArray('expanded')
  // }

  get lastScrollY () {
    const scrollY = this.$queryValue('y')
    return scrollY ? parseInt(scrollY) : undefined
  }

  get lastStartActionLogId () {
    return this.$queryValue('start')
  }

  get lastLimit () {
    const limit = this.$queryValue('limit')
    return limit ? parseInt(limit) : undefined
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentDomain () {
    return Object.values(this.domainModule.domains).find(o => o.handleId === this.currentDomainHandleId)
  }

  get versionCount () {
    return this.versionModule.versions.length
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get actionLogsListStatus () {
    return this.historyModule.actionLogsListStatus
  }

  get loading () {
    return (
      this.versionModule.travelling ||
      !this.landscapeModule.landscapesListStatus.success ||
      !this.organizationModule.organizationsListStatus.success
    )
  }

  get loadingInitial () {
    return this.actionLogsListStatus.idle || (this.actionLogsListStatus.loadingInfo.landscapeId === this.currentLandscapeId && !this.actionLogsListStatus.loadingInfo.filter?.endBeforeId && !this.actionLogsListStatus.loadingInfo.filter?.startAfterId)
  }

  get loadingPage () {
    return this.actionLogsListStatus.loadingInfo.landscapeId === this.currentLandscapeId && (!!this.actionLogsListStatus.loadingInfo.filter?.endBeforeId || !!this.actionLogsListStatus.loadingInfo.filter?.startAfterId)
  }

  get firstActionLog () {
    return this.historyModule.firstActionLog?.id === this.actionLogs[0]?.id
  }

  get lastActionLog () {
    return this.historyModule.lastActionLog?.id === this.actionLogs[this.actionLogs.length - 1]?.id
  }

  get headers () {
    return [
      {
        id: 'action',
        text: 'Action',
        width: '60%'
      },
      {
        id: 'performedBy',
        text: 'Performed by',
        width: '20%'
      },
      {
        id: 'timestamp',
        text: 'Date/time',
        width: '20%'
      }
      // {
      //   id: 'changes',
      //   text: 'Changes',
      //   width: '20%'
      // }
    ]
  }

  get actionLogs () {
    return this.historyModule.actionLogs
  }

  get items () {
    const items: IObjectItem[] = []

    this.actionLogs
      .map((o): IObjectItem => {
        const actionLabel = resolveActionLabel(o.action)
        return {
          ...o,
          actionLabel,
          content: actionLabel.content.map(o => ({
            ...o,
            link: o.link ? this.resolveLink(o.link) : undefined
          })),
          performedByLabel: {
            icon: o.performedBy === 'user' ? '$fas-user-circle' : '$fas-robot',
            text: o.performedByName
          }
        }
      })
      .forEach(o => {
        items.push(o)

        // const expanded = this.expandedActionLogIds.includes(o.id)

        // items.push({
        //   ...o,
        //   expanded
        // })

        // if (expanded) {
        //   const actionLogs = this.historyModule.actionLogChildren[o.id]?.map((c): IObjectItem => {
        //     const actionLabel = resolveActionLabel(c.action)
        //     return {
        //       ...c,
        //       actionLabel,
        //       content: actionLabel.content.map(o => ({
        //         ...o,
        //         link: o.link ? this.resolveLink(o.link) : undefined
        //       })),
        //       performedByLabel: o.performedByLabel
        //     }
        //   })

        //   items.push({
        //     ...o,
        //     changes: true,
        //     expanded,
        //     loading: !actionLogs
        //   })

        //   if (actionLogs) {
        //     items.push(...actionLogs.map(o => ({
        //       ...o,
        //       expanded
        //     })))
        //   }
        // }
      })

    return items
  }

  get resolveLink () {
    return (link: IActionLabelContentLink): RawLocation | undefined => {
      if (link.modelObjectHandleId) {
        const diagram = Object.values(this.diagramModule.diagrams).find(o => o.handleId === link.diagramHandleId)
        const modelObject = Object.values(this.modelModule.objects).find(o => o.handleId === link.modelObjectHandleId)

        const diagramObject = modelObject && diagram ? modelObject.diagrams[diagram.id] : undefined
        const diagramObjectDiagram = diagramObject ? this.diagramModule.diagrams[diagramObject.id] : undefined
        const diagramModel = diagramObjectDiagram ? this.modelModule.objects[diagramObjectDiagram.modelId] : undefined

        if (diagramObject && diagramObjectDiagram && diagramModel) {
          if (this.currentVersionId === 'latest') {
            return {
              name: 'editor-diagram',
              params: {
                landscapeId: this.currentLandscapeId
              },
              query: {
                diagram: diagramObjectDiagram.handleId,
                model: diagramModel.handleId,
                object: diagramObject.objectId,
                object_tab: 'history'
              }
            }
          } else {
            return {
              name: 'version-diagram',
              params: {
                landscapeId: this.currentLandscapeId,
                versionId: this.currentVersionId
              },
              query: {
                diagram: diagramObjectDiagram.handleId,
                model: diagramModel.handleId,
                object: diagramObject.objectId,
                object_tab: 'history'
              }
            }
          }
        } else {
          return {
            name: 'model-objects',
            params: {
              landscapeId: this.currentLandscapeId,
              versionId: this.currentVersionId
            },
            query: {
              focus: link.modelObjectHandleId,
              object: link.modelObjectHandleId,
              object_tab: 'history'
            }
          }
        }
      }
      if (link.commentHandleId) {
        const diagram = Object.values(this.diagramModule.diagrams).find(o => o.handleId === link.diagramHandleId)
        const comment = Object.values(this.commentModule.activeComments).find(o => o.handleId === link.commentHandleId) || Object.values(this.commentModule.comments).find(o => o.handleId === link.commentHandleId)

        const diagramObject = comment && diagram ? comment.diagrams[diagram.id] : undefined
        const diagramObjectDiagram = diagramObject ? this.diagramModule.diagrams[diagramObject.id] : undefined
        const diagramModel = diagramObjectDiagram ? this.modelModule.objects[diagramObjectDiagram.modelId] : undefined

        if (diagramObject && diagramObjectDiagram && diagramModel) {
          if (this.currentVersionId === 'latest') {
            return {
              name: 'editor-diagram',
              params: {
                landscapeId: this.currentLandscapeId
              },
              query: {
                comment: diagramObject.commentId,
                diagram: diagramObjectDiagram.handleId,
                model: diagramModel.handleId
              }
            }
          } else {
            return {
              name: 'version-diagram',
              params: {
                landscapeId: this.currentLandscapeId,
                versionId: this.currentVersionId
              },
              query: {
                comment: diagramObject.commentId,
                diagram: diagramObjectDiagram.handleId,
                model: diagramModel.handleId
              }
            }
          }
        }
      }
      if (link.modelConnectionHandleId) {
        const diagram = Object.values(this.diagramModule.diagrams).find(o => o.handleId === link.diagramHandleId)
        const modelConnection = Object.values(this.modelModule.connections).find(o => o.handleId === link.modelConnectionHandleId)

        const diagramConnection = modelConnection && diagram ? modelConnection.diagrams[diagram.id] : undefined
        const directConnection = modelConnection ? Object.values(modelConnection.diagrams).find(o => o.originModelId === modelConnection.originId && o.targetModelId === modelConnection.targetId) : undefined
        const lowerConnection = modelConnection ? Object.values(modelConnection.diagrams).find(o => o.originModelId !== modelConnection.originId || o.targetModelId !== modelConnection.targetId) : undefined

        const chosenDiagramConnection = diagramConnection || directConnection || lowerConnection
        const chosenDiagramConnectionDiagram = chosenDiagramConnection ? this.diagramModule.diagrams[chosenDiagramConnection.id] : undefined
        const chosenDiagramModel = chosenDiagramConnectionDiagram ? this.modelModule.objects[chosenDiagramConnectionDiagram.modelId] : undefined

        if (chosenDiagramConnection && chosenDiagramConnectionDiagram && chosenDiagramModel) {
          if (this.currentVersionId === 'latest') {
            return {
              name: 'editor-diagram',
              params: {
                landscapeId: this.currentLandscapeId
              },
              query: {
                connection: chosenDiagramConnection.connectionId,
                diagram: chosenDiagramConnectionDiagram.handleId,
                model: chosenDiagramModel.handleId
              }
            }
          } else {
            return {
              name: 'version-diagram',
              params: {
                landscapeId: this.currentLandscapeId,
                versionId: this.currentVersionId
              },
              query: {
                connection: chosenDiagramConnection.connectionId,
                diagram: chosenDiagramConnectionDiagram.handleId,
                model: chosenDiagramModel.handleId
              }
            }
          }
        }
      }
      if (link.diagramHandleId) {
        const diagram = Object.values(this.diagramModule.diagrams).find(o => o.handleId === link.diagramHandleId)
        const diagramModel = diagram ? this.modelModule.objects[diagram.modelId] : undefined
        if (diagram && diagramModel) {
          if (this.currentVersionId === 'latest') {
            return {
              name: 'editor-diagram',
              params: {
                landscapeId: this.currentLandscapeId
              },
              query: {
                diagram: diagram.handleId,
                model: diagramModel.handleId
              }
            }
          } else {
            return {
              name: 'version-diagram',
              params: {
                landscapeId: this.currentLandscapeId,
                versionId: this.currentVersionId
              },
              query: {
                diagram: diagram.handleId,
                model: diagramModel.handleId
              }
            }
          }
        }
      }
      if (link.diagramGroupHandleId) {
        const diagramGroup = Object.values(this.diagramModule.diagramGroups).find(o => o.handleId === link.diagramGroupHandleId)
        const diagram = Object.values(this.diagramModule.diagrams).filter(o => o.groupId === diagramGroup?.id).sort(sort.index).find(o => o)
        if (diagramGroup && diagram) {
          if (this.currentVersionId === 'latest') {
            return {
              name: 'editor-diagram',
              params: {
                landscapeId: this.currentLandscapeId
              },
              query: {
                diagram: diagram.handleId
              }
            }
          } else {
            return {
              name: 'version-diagram',
              params: {
                landscapeId: this.currentLandscapeId,
                versionId: this.currentVersionId
              },
              query: {
                diagram: diagram.handleId
              }
            }
          }
        }
      }
      if (link.domainHandleId) {
        return {
          name: 'diagrams',
          params: {
            landscapeId: this.currentLandscapeId,
            versionId: this.currentVersionId
          },
          query: {
            domain: link.domainHandleId
          }
        }
      }
      if (link.flowHandleId) {
        const flow = Object.values(this.flowModule.flows).find(o => o.handleId === link.flowHandleId)
        const diagram = flow ? this.diagramModule.diagrams[flow.diagramId] : undefined
        if (flow && diagram) {
          if (this.currentVersionId === 'latest') {
            return {
              name: 'editor-diagram',
              params: {
                landscapeId: this.currentLandscapeId
              },
              query: {
                diagram: diagram.handleId,
                flow: flow.handleId,
                flow_path: undefined,
                flow_step: undefined
              }
            }
          } else {
            return {
              name: 'version-diagram',
              params: {
                landscapeId: this.currentLandscapeId,
                versionId: this.currentVersionId
              },
              query: {
                diagram: diagram.handleId,
                flow: flow.handleId,
                flow_path: undefined,
                flow_step: undefined
              }
            }
          }
        }
      }
    }
  }

  @Watch('items')
  async onItemsChanged () {
    await this.$nextTick()
    this.resize()
  }

  // @Watch('expandedActionLogIds')
  // async onCurrentExpandedActionLogIdsChanged () {
  //   await this.$nextTick()
  //   this.resize()
  // }

  @Watch('limit')
  onLimitChanged () {
    if (!this.loading) {
      this.scrollY = 0
      this.load()
    }
  }

  @Watch('loading')
  onLoadingChanged (loading: boolean, prevLoading: boolean) {
    if (prevLoading && !loading) {
      this.load()
    }
  }

  @Watch('currentLandscape')
  onCurrentLandscapeChanged (currentLandscape?: Landscape, prevCurrentLandscape?: Landscape) {
    if (currentLandscape && currentLandscape?.id !== prevCurrentLandscape?.id) {
      analytics.historyScreen.track(this, {
        landscapeId: [currentLandscape.id],
        organizationId: [currentLandscape.organizationId]
      })
    }
  }

  @Watch('currentVersion')
  onCurrentVersionChanged (currentVersion?: Version, prevCurrentVersion?: Version) {
    if (currentVersion && currentVersion?.id !== prevCurrentVersion?.id) {
      this.scrollY = 0
      this.startActionLogId = undefined
      this.updateQueryDebounce()
    }
  }

  @Watch('versionCount')
  onVersionCountChanged (versionCount: number, prevVersionCount: number) {
    if (versionCount > prevVersionCount) {
      this.scrollY = 0
      this.startActionLogId = undefined
      this.load()
    }
  }

  @Watch('currentDomainHandleId')
  onCurrentDomainHandleIdChanged () {
    if (!this.loading) {
      this.scrollY = 0
      this.startActionLogId = undefined
      this.load()
    }
  }

  mounted () {
    this.scrollY = this.lastScrollY || 0
    this.limit = this.lastLimit || 20
    this.startActionLogId = this.lastStartActionLogId || undefined

    this.resize()

    if (!this.loading) {
      this.load()
    }

    if (this.currentLandscape) {
      analytics.historyScreen.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  destroyed () {
    this.updateQueryDebounce.cancel()
  }

  async load () {
    if (this.actionLogsListStatus.loadingInfo.landscapeId !== this.currentLandscapeId) {
      const { actionLogs } = await this.historyModule.actionLogsList({
        filter: {
          actionId: this.currentDomain?.handleId,
          includeActionsInContext: true,
          limit: this.limit,
          startAtId: this.startActionLogId
        },
        landscapeId: this.currentLandscapeId
      })
      this.startActionLogId = actionLogs[0]?.id

      if (this.virtualScrollRef) {
        this.virtualScrollRef.$el.scrollTop = this.scrollY
      }

      this.updateQueryDebounce()
    }
  }

  updateQueryDebounce = debounce(this.updateQuery.bind(this), 500, {
    leading: false,
    trailing: true
  })

  async updateQuery () {
    const query: any = {}
    if (this.scrollY) {
      query.y = (Math.round(this.scrollY * 10) / 10) || undefined
    } else if (this.lastScrollY) {
      query.y = undefined
    }
    if (this.startActionLogId) {
      query.start = this.startActionLogId
    } else if (this.lastStartActionLogId) {
      query.start = undefined
    }
    if (this.limit !== 20) {
      query.limit = this.limit
    } else if (this.lastLimit) {
      query.limit = undefined
    }
    if (Object.keys(query).length) {
      await this.$replaceQuery(query)
    }
  }

  async loadPrev () {
    this.scrollY = 0

    const { actionLogs } = await this.historyModule.actionLogsList({
      filter: {
        actionId: this.currentDomain?.handleId,
        endBeforeId: this.actionLogs[0].id,
        includeActionsInContext: true,
        limitToLast: this.limit
      },
      landscapeId: this.currentLandscapeId
    })
    this.startActionLogId = actionLogs[0]?.id

    if (this.virtualScrollRef) {
      this.virtualScrollRef.$el.scrollTop = this.scrollY
    }

    this.updateQueryDebounce()
  }

  async loadNext () {
    this.scrollY = 0

    const { actionLogs } = await this.historyModule.actionLogsList({
      filter: {
        actionId: this.currentDomain?.handleId,
        includeActionsInContext: true,
        limit: this.limit,
        startAfterId: this.actionLogs[this.actionLogs.length - 1].id
      },
      landscapeId: this.currentLandscapeId
    })
    this.startActionLogId = actionLogs[0]?.id

    if (this.virtualScrollRef) {
      this.virtualScrollRef.$el.scrollTop = this.scrollY
    }

    this.updateQueryDebounce()
  }

  resize () {
    this.windowWidth = window.innerWidth
    this.scrollYVisible = this.virtualScrollRef ? this.virtualScrollRef.$el.scrollHeight > this.virtualScrollRef.$el.clientHeight : false
    this.virtualScrollRef?.onScroll()
  }
}
